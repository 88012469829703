import React from 'react';
import { useStaticQuery, graphql} from "gatsby";
import Layout from "../components/Layout";
import SiteHelmet from "../components/SiteHelmet";
import DataTable from "../components/DataTable";

const BoardgamesTable = () => {
  const data = useStaticQuery(
    graphql`
        query {
            allBoardgamesJson {
                edges {
                    node {
                        title
                        playTime
                        players
                        bggRating
                        weight
                        own
                    }
                }
            }
        }
    `,
  );

  const games = data.allBoardgamesJson.edges.filter(game => {
    return game.node.own === true
  }).map(game => {
    const modifiedGame = {
      title: game.node.title,
      bggRating: game.node.bggRating,
      weight: game.node.weight,
      minPlayers: game.node.players[0],
      minPlayTime: game.node.playTime[0],
      maxPlayTime: game.node.playTime[1],
    };
    game.node.players[1] === undefined
      ? modifiedGame.maxPlayers = game.node.players[0]
      : modifiedGame.maxPlayers = game.node.players[1];
    return(modifiedGame)
  });

  const tableColumns = [
    { title: 'Game', field: 'title' },
    { title: 'Min Players', field: 'minPlayers' },
    { title: 'Max Players', field: 'maxPlayers' },
    { title: 'Min Play Time', field: 'minPlayTime' },
    { title: 'Max Play Time', field: 'maxPlayTime' },
    { title: 'BoardGameGeek Rating', field: 'bggRating' },
    { title: 'Complexity', field: 'weight' },
  ];

  return (
    <Layout>
      <SiteHelmet title="Board Games Table"/>
      <DataTable
        columns={tableColumns}
        data={games}
        title={"My Board Games"}
        useFiltering={true}
      />
    </Layout>
  );
};

export default BoardgamesTable;